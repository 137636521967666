<template>
  <div class="m-4">
    <h1>Register user</h1>
    <form class="login-form" @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          class="form-input grey-text"
          type="email"
          placeholder="Email"
          id="email"
          v-model="email"
          required
        />
      </div>
      <div
        class="form-group mt-2"
        v-if="this.role === 'location_user' && !this.sponsorAccount"
      >
        <input
          class="form-input grey-text"
          type="number"
          placeholder="Number of children"
          id="nChildren"
          v-model="nChildren"
          required
        />
      </div>
      <button class="btn btn-primary mt-2" type="submit" id="login">
        Create
      </button>
    </form>
  </div>
</template>

<script>
import {
  createLocationCode,
  registrationEmail,
} from "@/services/location/location.js";
import { useToast } from "vue-toastification";

export default {
  name: "CreateUserLocation",
  props: {
    location: String,
    role: String,
    sponsorAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      nChildren: "",
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.toast.info("Sending user email...");

        const data = await createLocationCode(this.location, this.role);

        this.sendRegistrationEmail(data.data.location_code);
      } catch (e) {
        this.toast.error(
          "Something went wrong creating the location code, please try again"
        );
      }
    },
    async sendRegistrationEmail(code) {
      try {
        const isLocationUser =
          this.role === "location_user" && !this.sponsorAccount;
        const data = await registrationEmail(
          this.email,
          code,
          this.nChildren,
          isLocationUser,
          this.sponsorAccount
        );
        if (data.success) {
          this.toast.success(`The email was sent to ${this.email}`);
        }
      } catch (e) {
        this.toast.error(
          "Something went wrong sending the email, please try again"
        );
      }
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
};
</script>
